<template>
	<main>
		<article class="cabecera-interior" style="background-image: url(/images/fondo-equipo-1.jpg);">
		<div class="cabecera-interior-caja">
			<div class="container">
			<div class="titulo-cabecera columns four">
				<h1>Team</h1>
			</div>
			<div class="contenido-cabecera columns seven offset-by-one">
				<h2>We are a group of professionals with a distinguished track record in investments</h2>
				<p>We combine multiple experiences, having worked in investment funds, private equity, trading desks, corporate finance, and private banking.</p>
			</div>
			</div>
			<a href="#seccion-equipo" class="scroll"></a>
		</div>
		</article>
		<section id="seccion-equipo" class="seccion full">
		<div class="container-big">
			<div class="columns eight offset-by-two">
			<div class="tipo" v-for="(grupo, grupoIndex) in grupos" :key="grupoIndex">
				<h2>{{ grupo.nombre }}</h2>
				<div class="row">
				<div v-for="persona in grupo.miembros" :key="persona.id" class="miembro-equipo four columns same">
					<router-link :to="{ name: 'PersonaDetalleEng', params: { id: persona.id } }" class="hover">
					<div class="hover-img"><span>SHOW MORE</span></div>
					<img width="1082" height="1077" :src="persona.imagen" :alt="persona.nombre" class="attachment-full size-full wp-post-image"  sizes="(max-width: 1082px) 100vw, 1082px"/>
					<div class="caja-equipo">
						<h3 v-html="persona.nombre"></h3>
						<h4>{{ persona.cargo }}</h4>
					</div>
					</router-link>
				</div>
				</div>
			</div>
			</div>
		</div>
		</section>
	</main>
	</template>
	<script>
	import jQuery	from 'jquery';
	import { useHead } from '@vueuse/head';
	export default {
	name: 'EquipoEng',
	setup() {
    useHead({
      title: 'Team | VICAPITAL',
      meta: [
        { name: 'description', content: '' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'Team | VICAPITAL' },
        { property: 'og:description', content:  '' },
        { property: 'og:url', content: 'https://vicapital.cl/en/equipo' },
        { property: 'og:locale', content: 'en_US' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/en/equipo' }]
    });
  },
	data() {
	return {
	grupos: [
				{
					nombre: 'Partners',
					miembros: [
						{
							id: 'ignacio-silva',
							nombre: 'Ignacio<br />Silva',
							nombreHTML: 'Ignacio<br />Silva<br />Lombardi',
							cargo: 'Partner',
							imagen: '/images/equipo/Ignacio-Silva-Lombardi.png',
							imagen_grande: '/images/equipo/Ignacio-silva.jpg',
							texto: "<p>Ignacio joined Vicapital as a partner in July 2014. Previously, Ignacio worked at IM Trust (currently Credicorp Capital) in the investment banking division, participating in mergers and acquisitions, bond issuances, and initial public offerings. Later, between 2008 and 2012, Ignacio worked at Southern Cross Group, one of the largest private equity managers in Latin America, participating in the management of portfolio companies and searching for investment opportunities in Latin America.</p><p>Ignacio holds a Business Administration degree from the Pontificia Universidad Católica de Chile and earned his MBA from The UCLA Anderson School of Business, where he received the J. Fred Weston Award (Excellence in Finance). During his MBA, Ignacio participated in a group that managed a portion of UCLA&#8217;s endowment.</p>",
							email: 'isilva@vicapital.cl'
						},
						{
							id: 'daniela-infante',
							nombre: 'Daniela<br />Infante',
							nombreHTML: 'DANIELA<br />INFANTE<br />Stewart',
							cargo: 'Partner',
							imagen: '/images/equipo/DANIELA-INFANTE.png',
				imagen_grande: '/images/equipo/DANIELA-INFANTE.jpg',
							texto: "<p>Co-founder of Vicapital. Daniela has over 20 years of experience in the capital market. Previously, Daniela worked in finance at Concha y Toro. Later, in 1999, she joined Tanner. As Commercial Manager of Tanner, she led the area, managing high net worth client portfolios and developing Tanner&#8217;s commercial strategy. She became a partner in the firm. In 2012, she was part of the founding team of Vicapital.</p><p>Daniela holds a Business Administration degree from the Pontificia Universidad Católica de Chile.</p>",
							email: 'dinfante@vicapital.cl'
						},
						{
							id: 'emiliano-villaseca',
							nombre: 'Emiliano<br />Villaseca',
							nombreHTML: 'EMILIANO<br />VILLASECA<br />Gorman',
							cargo: 'Partner',
							imagen: '/images/equipo/EMILIANO-VILLASECA.png',
				imagen_grande: '/images/equipo/Emiliano-Villaseca.jpg',
							texto: "<p>Co-founder of Vicapital. Emiliano has over 18 years of experience in the capital market. Since 2004, he was part of the Tanner group of companies, where he held the positions of Investment Manager, Commercial Manager, General Manager, and Executive Director. During his years at Tanner, Emiliano managed investor portfolios, developed the company&#8217;s commercial plan, led the trading desk team, developed the Investment Funds area, and worked on the development and implementation of the company&#8217;s strategic plan. In 2012, he was part of the founding team of Vicapital.</p><p>Emiliano holds a Business Administration degree from the Pontificia Universidad Católica de Chile.</p>",
							email: 'evillaseca@vicapital.cl'
						}
					]
				},
				{
					nombre: 'Advisory Board',
					miembros: [
						{
							id: 'jose-de-gregorio',
							nombre: 'José<br />De Gregorio',
							nombreHTML: 'José<br />De Gregorio<br />Rebeco',
							cargo: 'Advisor',
							imagen: '/images/equipo/Jose-De-Gregorio.png',
				imagen_grande: '/images/equipo/Jose-De-Gregorio.jpg',
							texto: "<p>José is the dean of the Faculty of Economics and Business at the University of Chile. He was President of the Central Bank and Tri-Minister in the Ministries of Economy, Mining, and Energy of Chile.</p><p>He holds a Master&#8217;s degree in Engineering and Industrial Civil Engineering from the University of Chile, and a Ph.D. in Economics from MIT.</p>",
							//email: 'jose@vicapital.cl'
						},
						{
							id: 'alfredo-alcaino',
							nombre: 'Alfredo<br />Alcaíno',
							nombreHTML: 'ALFREDO<br />ALCAÍNO<br />Lamarca',
							cargo: 'Advisor',
							imagen: '/images/equipo/ALFREDO-ALCAINO-1.jpg',
				imagen_grande: '/images/equipo/ALFREDO-ALCAINO-2.jpg',
							texto: "<p>Alfredo is a partner at Assetplan, the largest residential rental operator in Chile. He previously worked at Celfin Capital, Veta3, and the government of Chile.</p><p>He holds a Civil Engineering degree from the Pontificia Universidad Católica de Chile and an MBA from the Kellogg School of Management.</p>",
							//email: 'aalcaino@vicapital.cl'
						},
						{
							id: 'eduardo-torretti',
							nombre: 'Eduardo<br />Torretti',
							nombreHTML: 'Eduardo<br />Torretti<br />Schmidt',
							cargo: 'Advisor',
							imagen: '/images/equipo/Eduardo-Torreti.png',
				imagen_grande: '/images/equipo/Eduardo-Torreti.jpg',
							texto: "<p>Eduardo is a partner at Torretti &amp; Cía, a law firm specializing in tax law, with a solid practice in corporate matters, business families, and high net worth clients.</p><p>He holds a Law degree from the Pontificia Universidad Católica de Chile and an LLM from the University of Leiden, the Netherlands.</p>",
							//email: 'etorretti@vicapital.cl'
						}
					]
				},
				{
					nombre: 'Team',
					miembros: [
						{
							id: 'pablo-achondo',
							nombre: 'Pablo<br />Achondo',
							nombreHTML: 'Pablo<br />Achondo<br />Díaz',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/Pablo-Achondo.png',
				imagen_grande: '/images/equipo/Pablo-Achondo.jpg',
							texto: "<p>Pablo joined Vicapital in October 2013.</p><p>Prior to joining Vicapital, he worked at AFP Provida.</p><p>Pablo holds a degree in Commercial Engineering from Universidad Andrés Bello.</p>",
							email: 'pachondo@vicapital.cl'
						},
						{
							id: 'isabel-allendes',
							nombre: 'Isabel<br />Allendes',
							nombreHTML: 'ISABEL<br />ALLENDES<br />Correa',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/Isabel-Allendes-1-2.jpg',
				imagen_grande: '/images/equipo/Isabel-Allendes-2.jpg',
							texto: "<p>Isabel joined Vicapital in November 2020.</p><p>Previously, she worked as an Investor Relations professional at Banco de Chile and at the trading desk and retail broker at Banco de Crédito e Inversiones brokerage unit.</p><p>Isabel holds a degree in Commercial Engineering from Universidad Adolfo Ibáñez.</p>",
							email: 'iallendes@vicapital.cl'
						},
						
						
						{
							id: 'rodrigo-montes',
							nombre: 'Rodrigo<br />Montes',
							nombreHTML: 'RODRIGO<br />MONTES<br />PÉREZ DE ARCE',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/Rodrigo-Montes.png',
				imagen_grande: '/images/equipo/Rodrigo-Montes.jpg',
							texto: "<p>Rodrigo joined Vicapital in March 2022.</p><p>Prior to Vicapital, he worked in the administration and structuring of alternative investment funds at FYNSA AGF.</p><p>Rodrigo holds a degree in Commercial Engineering from the Pontificia Universidad Católica de Chile.</p>",
							email: 'rmontes@vicapital.cl'
						},
						{
							id: 'julio-pesce',
							nombre: 'Julio<br />Pesce',
							nombreHTML: 'JULIO<br />PESCE<br />Soto',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/Julio-Pesce.png',
				imagen_grande: '/images/equipo/Julio-Pesce.jpg',
							texto: "<p>Julio joined Vicapital in November 2021.</p><p>He holds a degree in Commercial Engineering with a specialization in Economics from the University of Chile, and a Master&#8217;s degree in Economic Analysis from the same institution.</p>",
							email: 'jpesce@vicapital.cl'
						},
						{
							id: 'maria-luisa',
							nombre: 'María Luisa<br />Pérez de Arce',
							nombreHTML: 'MARÍA<br />LUISA<br />PÉREZ DE ARCE',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/normal.jpg',
				imagen_grande: '/images/equipo/malu-codo.jpg',
							texto: "<p>María Luisa joined Vicapital in May 2024.</p><p>She holds a degree in Commercial Engineering from Universidad de los Andes.</p>",
							email: 'mperezdearce@vicapital.cl'
						},
						{
							id: 'romina-galvez',
							nombre: 'Romina<br />Galvez',
							nombreHTML: 'ROMINA<br />GALVEZ<br />YEFI',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/Romina-Galvez.png',
				imagen_grande: '/images/equipo/Romina-Galvez.jpg',
							texto: "<p>Romina joined Vicapital in December 2019.</p><p>She holds a degree in Commercial Engineering from Universidad de los Andes.</p>",
							email: 'rgalvez@vicapital.cl'
						},
						{
							id: 'enrique-molinare',
							nombre: 'Enrique<br />Molinare',
							nombreHTML: 'ENRIQUE<br />MOLINARE<br />LEÓN',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/enrique-normal.jpeg',
								imagen_grande: '/images/equipo/enrique-codo.jpeg',
							texto: "<p>Enrique joined Vicapital in November 2024.</p><p>Enrique holds a degree in Commercial Engineering from the Pontificia Universidad Católica de Chile.</p>",
							email: 'emolinare@vicapital.cl'
							},
							{
							id: 'gonzalo-araos',
							nombre: 'Gonzalo<br />Araos',
							nombreHTML: 'GONZALO<br />ARAOS<br />BAERISWYL',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/Gonzalo-Araos.png',
				imagen_grande: '/images/equipo/gonzalo-araos.jpg',
							texto: "<p>Gonzalo joined Vicapital in June 2022.</p><p>Prior to Vicapital, he worked as an analyst for the international balanced funds of the General Fund Manager at BICE Inversiones.</p><p>Gonzalo holds a degree in Commercial Engineering from the Pontificia Universidad Católica de Chile.</p>",
							email: 'garaos@vicapital.cl'
						},
						{
							id: 'maximiliano-aburto',
							nombre: 'Maximiliano<br />Aburto',
							nombreHTML: 'MAXIMILIANO<br />ABURTO<br />Soto',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/Maximiliano-Aburto.png',
				imagen_grande: '/images/equipo/Maximiliano-Aburto.jpg',
							texto: "<p>Maximiliano joined Vicapital in July 2019.</p><p>He holds a degree in Commercial Engineering with a specialization in Economics from the University of Chile.</p>",
							email: 'maburto@vicapital.cl'
						},
						{
							id: 'sergio-contreras',
							nombre: 'Sergio<br />Contreras',
							nombreHTML: 'SERGIO<br />CONTRERAS<br />AGUILAR',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/sergio-normal.jpeg',
								imagen_grande: '/images/equipo/sergio-codo.jpeg',
							texto: "<p>Sergio joined Vicapital in October 2024.</p><p>Sergio is a professional with extensive experience in financial services companies.</p>",
							email: 'scontreras@vicapital.cl'
							},
							{
							id: 'victor-neira',
							nombre: 'Víctor<br />Neira',
							nombreHTML: 'VÍCTOR<br />NEIRA<br />ESPÍNDOLA',
							//cargo: 'Inversiones',
							imagen: '/images/equipo/victor-normal.jpeg',
								imagen_grande: '/images/equipo/victor-codo.jpeg',
							texto: "<p>Víctor joined Vicapital in November 2024.</p><p>He holds a degree in Industrial Engineering from the Universidad de Santiago de Chile.</p>",
							email: 'vneira@vicapital.cl'
							},
							
						{
							id: 'patricio-ramirez',
							nombre: 'Patricio<br />Ramirez',
							nombreHTML: 'PATRICIO<br />RAMIREZ<br />VILCHES',
							//cargo: 'Administración y Finanzas',
							imagen: '/images/equipo/Patricio-Ramirez.png',
				imagen_grande: '/images/equipo/Patricio-Ramirez.jpg',
							texto: "<p>Patricio joined Vicapital in April 2022.</p><p>He holds a degree in Information and Management Control Engineering from the University of Chile.</p>",
							email: 'pramirez@vicapital.cl'
						},
						{
							id: 'juan-carlos-obreque',
							nombre: 'Juan Carlos<br />Obreque',
							nombreHTML: 'Juan<br />Carlos<br />Obreque',
							//cargo: 'Mercados',
							imagen: '/images/equipo/Juan-Carlos-Obreque.png',
				imagen_grande: '/images/equipo/Juan-Carlos-Obreque.jpg',
							texto: "<p>Juan Carlos joined Vicapital in January 2013.</p><p>Previously, Juan Carlos worked in the operations and treasury division at Tanner Corredores de Bolsa and Euroamérica.</p>",
							email: 'jobreque@vicapital.cl'
						},

						{
							id: 'esteban-tapia',
							nombre: 'Esteban<br />Tapia',
							nombreHTML: 'ESTEBAN<br />TAPIA<br />MANZANO',
							//cargo: 'Tecnología',
							imagen: '/images/equipo/43841-e1697817472577.jpg',
				imagen_grande: '/images/equipo/et-codo.jpg',
							texto: "<p>Esteban joined Vicapital in June 2023.</p><p>He holds a degree in Civil Engineering in Computer Science from Universidad Técnica Federico Santa María.</p>",
							email: 'etapia@vicapital.cl'
						},
						{
							id: 'maria-jose-prieto',
							nombre: 'María José<br />Prieto',
							nombreHTML: 'MARÍA<br />JOSÉ<br />PRIETO',
							//cargo: 'Office Manager',
							imagen: '/images/equipo/cote-cortada2.jpg',
				imagen_grande: '/images/equipo/cote-normal.jpg',
							texto: "<p>María José joined Vicapital in July 2021.</p><p>She holds a degree in Commercial Engineering from Universidad de los Andes.</p>",
							email: 'mjprieto@vicapital.cl'
						},
						{
							id: 'carolina-ramirez',
							nombre: 'Carolina<br />Ramirez',
							nombreHTML: 'CAROLINA<br />RAMIREZ<br />RIVERA',
							//cargo: 'Asistente Ejecutiva',
							imagen: '/images/equipo/CAROLINA-RAMIREZ-1-2.jpg',
				imagen_grande: '/images/equipo/CAROLINA-RAMIREZ-2.jpg',
							texto: "<p>Carolina joined Vicapital in September 2012.</p><p>Prior to this, she worked as an executive assistant at Tanner Corredores de Bolsa.</p><p>Carolina is a Bilingual Secretary.</p>",
							email: 'cramirez@vicapital.cl'
						},
						{
							id: 'pedro-gonzalez',
							nombre: 'Pedro<br />Gonzalez',
							nombreHTML: 'PEDRO<br />GONZALEZ<br />MATURANA',
							//cargo: 'Asistente',
							imagen: '/images/equipo/Pedro-GONZALES.png',
				imagen_grande: '/images/equipo/Pedro-GONZALES.jpg',
							texto: "<p>Pedro joined Vicapital in January 2017.</p>",
							//email: 'pgonzales@vicapital.cl'
						},
						
					]
				}
			],
	};
	},

	methods: {

	initPage() {
	const $j = jQuery.noConflict();
	//console.log("Iniciando mounted servicios")
	const id = (v) => document.getElementById(v);
	var ovrl = id("overlay");
	ovrl.style.display = "none";

	var logo_opacity = id("logo-header");
    logo_opacity.style.opacity = 1;

	$j('.scroll').on('click', function(event) {
	event.preventDefault();
	const target = this.hash;
	const $target = $j(target);

	$j('html, body').animate({
	scrollTop: $target.offset().top
	}, 2000, function() {
	});
	});
	// Equal height function
	

	// Window scroll and load functions
	const setupScrollEvents = () => {
	//console.log("Función principal de jQuery ejecutada");
	var height = $j(window).height(),
	header = $j("header");

	$j(window).scroll(() => {
	var scroll = $j(window).scrollTop();
	if (scroll >= height) {
		header.removeClass('sin-scroll').addClass("scroll");
	} else {
		header.removeClass("scroll").addClass('sin-scroll');
	}
	});

	$j(window).scroll(() => {
	var scroll = $j(window).scrollTop(),
		triggerBlur = $j(window).height() / 3;
	if (scroll >= triggerBlur) {
		$j('.full.blur').addClass("activo");
	} else {
		$j('.full.blur').removeClass("activo");
	}
	});
	};
	setupScrollEvents();

	

$j(window).resize(function() {
	const equalheight = (container) => {
	let currentTallest = 0,
	currentRowStart = 0,
	rowDivs = [],
	$el,
	topPosition = 0;

	$j(container).each(function() {
	$el = $j(this);
	$j($el).height('auto');
	topPosition = $el.position().top;

	if (currentRowStart != topPosition) {
	for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
		rowDivs[currentDiv].height(currentTallest);
	}
	rowDivs.length = 0; // empty the array
	currentRowStart = topPosition;
	currentTallest = $el.height();
	rowDivs.push($el);
	} else {
	rowDivs.push($el);
	currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
	}
	for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
	rowDivs[currentDiv].height(currentTallest);
	}
	});
	};
	equalheight('.same');
	const height = $j(window).height();
	const width = $j(window).width();
	if (width > 950){
	$j('.lamina').css('height',height);
	}
	});
}
,
setupWindowLoadEvent() {
	const equalheight = (container) => {
	let currentTallest = 0,
	currentRowStart = 0,
	rowDivs = [],
	$el,
	topPosition = 0;

	$j(container).each(function() {
	$el = $j(this);
	$j($el).height('auto');
	topPosition = $el.position().top;

	if (currentRowStart != topPosition) {
	for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
		rowDivs[currentDiv].height(currentTallest);
	}
	rowDivs.length = 0; // empty the array
	currentRowStart = topPosition;
	currentTallest = $el.height();
	rowDivs.push($el);
	} else {
	rowDivs.push($el);
	currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
	}
	for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
	rowDivs[currentDiv].height(currentTallest);
	}
	});
	};
	const $j = jQuery.noConflict();
	equalheight('.same');
	const height = $j(window).height();
	const width = $j(window).width();
	if (width > 950){
	$j('.lamina').css('height',height);
	}

	const numeroMenu = (container) => {
	let contador = 0;
	$j(container).each(function() {
	contador++;
	const $el = $j(this);
	if (!$el.hasClass('con-numero')) {
		$el.prepend('<span>0'+contador+'.</span>');
		$el.addClass('con-numero');
	}
	});
	};

	

	numeroMenu('.menu-item');
	equalheight('.same');

	// Menu functions
	$j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

	$j('.open-menu').click(function() {
	$j('header').toggleClass('open');
	$j('body').toggleClass('open-body');
	$j(this).toggleClass('open');
	return false;
	});
	/*
	$j('nav.principal .menu-item a').click(function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
	let target = $j(this.hash);
	target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
	if (target.length) {
		$j('html,body').animate({
		scrollTop: target.offset().top
		}, 2000);
		$j('header').toggleClass('open');
		$j('body').toggleClass('open-body');
		$j('.open-menu').toggleClass('open');
		return false;
	}
	}
	});
	*/

	$j('nav.info a').click(function() {
	const target = $j(this.hash);
	$j('.pop-left').removeClass('open');
	$j(target).toggleClass('open');
	return false;
	});

	$j('a.close-pop').click(function() {
	const target = $j(this.hash);
	$j(target).toggleClass('open');
	return false;
	});

	$j('.nav-responsive .nav-click').on('click', function() {
	if($j(this).parent().hasClass('activo')){
		$j('nav.nav .menu-item').removeClass('activo');
	} else {
	$j('nav.nav .menu-item').removeClass('activo');
	$j(this).parent().toggleClass('activo');
	}
	});
	/*
	$j('.scroll').click(function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
	let target = $j(this.hash);
	target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
	if (target.length) {
		$j('html,body').animate({
		scrollTop: target.offset().top
		}, 2000);
		return false;
	}
	}
	});
	*/
	
	},



	
	},

	mounted() {
	this.$root.personas = this.grupos.flatMap(grupo => grupo.miembros);
	this.initPage();
	this.setupWindowLoadEvent();
	}
 
	}

	</script>
	
	<style scoped></style>
	